@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Baskervville&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap');



 @font-face {
        font-family: 'Adobe-Garamond-Pro-Regular';
        font-style: normal;
        font-weight: normal;
        src: local('Adobe-Garamond-Pro-Regular'), url('Garamond-font/AGaramondPro-Regular.woff') format('woff');
        }

        @font-face {
            font-family: 'Adobe-Garamond-Semibold';
            font-style: normal;
            font-weight: normal;
            src: local('Adobe-Garamond-Semibold'), url('Garamond-font/Adobe-Garamond-Semibold-Regular.otf') format('otf');
            } 


@font-face {
    font-family: 'Adobe Garamond Pro Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Adobe Garamond Pro Regular'), url('Garamond-font/AGaramondPro-Regular.woff') format('woff');
}


@font-face {
    font-family: 'Adobe Garamond Pro Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Adobe Garamond Pro Italic'), url('Garamond-font/AGaramondPro-Italic.woff') format('woff');
}


@font-face {
    font-family: 'Adobe Garamond Pro semi-Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Adobe Garamond Pro semi-Bold'), url('Garamond-font/AGaramondPro-Bold.woff') format('woff');
}


@font-face {
    font-family: 'Adobe Garamond Pro Bold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Adobe Garamond Pro Bold Italic'), url('Garamond-font/AGaramondPro-BoldItalic.woff') format('woff');
} 




/*Font face*/
/*Custom Css*/
:root {
    /* --main-primary-color: #ff8080; */
    --main-primary-color: #d8b561;
    --MuiDocs-navDrawer-width: 0px;
}

a:hover {
    color: #000;
    text-decoration: none !important;
}

*,
*:before,
*:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html {
    color: #212123;
    height: 101%;
}

body {
    padding: 0;
    font-family: 'Poppins', sans-serif !important;
    /* -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        -o-user-select: none;
        user-select: none; */
}

ul.rcs_header_menu h6 a {
    font-family: 'Poppins', sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Adobe Garamond Pro semi-Bold' !important;
}

button {
    font-family: 'Adobe Garamond Pro Regular' !important;
}

/* Links
    -------------------------------------------------------------------------- */
a:link,
a:visited,
a:focus,
a:hover,
a:active {
    text-decoration: none;
}

a:focus,
a:hover,
a:active {
    text-decoration: none;
}

a:focus,
a:active {
    outline: none;
}

p,
ul,
ol,
dl,
pre,
table,
blockquote {
    margin-bottom: 1.5rem;
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

ul ul,
ol ol,
dl dl,
ul ol,
ul dl,
ol ul,
ol dl,
dl ul,
dl ol {
    margin-bottom: 0;
}

@media (min-width: 1440px) {
    html {
        font-size: 90%;
    }
}

/*Custom Css*/

/*iphone css zoom*/
/*** iPhone and iOS Form Input Zoom Fixes ***/
/* Fix Input Zoom on devices older than iPhone 5: */
@media screen and (device-aspect-ratio: 2/3) {

    select,
    textarea,
    input[type="text"],
    input[type="password"],
    input[type="datetime"],
    input[type="datetime-local"],
    input[type="date"],
    input[type="month"],
    input[type="time"],
    input[type="week"],
    input[type="number"],
    input[type="email"],
    textarea.inp-pd,
    input[type="url"] {
        font-size: 16px !important;
    }

    .gemslider button.slick-prev.slick-arrow {
        left: -25px;
        font-size: 16px;
        top: 3px;
    }

    .gemslider button.slick-next.slick-arrow {
        right: -25px;
        font-size: 16px;
        top: 3px;
    }
}

/* Fix Input Zoom on iPhone 5, 5C, 5S, iPod Touch 5g */
@media screen and (device-aspect-ratio: 40/71) {

    select,
    textarea,
    input[type="text"],
    input[type="password"],
    input[type="datetime"],
    input[type="datetime-local"],
    input[type="date"],
    input[type="month"],
    input[type="time"],
    input[type="week"],
    input[type="number"],
    input[type="email"],
    textarea.inp-pd,
    input[type="url"] {
        font-size: 16px !important;
    }

    .gemslider button.slick-prev.slick-arrow {
        left: -25px;
        font-size: 16px;
        top: 3px;
    }

    .gemslider button.slick-next.slick-arrow {
        right: -25px;
        font-size: 16px;
        top: 3px;
    }
}

/* Fix Input Zoom on iPhone 6, iPhone 6s, iPhone 7  */
@media screen and (device-aspect-ratio: 375/667) {

    select,
    textarea,
    input[type="text"],
    input[type="password"],
    input[type="datetime"],
    input[type="datetime-local"],
    input[type="date"],
    input[type="month"],
    input[type="time"],
    input[type="week"],
    input[type="number"],
    input[type="email"],
    textarea.inp-pd,
    input[type="url"] {
        font-size: 16px !important;
    }

    .gemslider button.slick-prev.slick-arrow {
        left: -25px;
        font-size: 16px;
        top: 3px;
    }

    .gemslider button.slick-next.slick-arrow {
        right: -25px;
        font-size: 16px;
        top: 3px;
    }
}

/* Fix Input Zoom on iPhone 6 Plus, iPhone 6s Plus, iPhone 7 Plus, iPhone 8, iPhone X, XS, XS Max  */
@media screen and (device-aspect-ratio: 9/16) {

    select,
    textarea,
    input[type="text"],
    input[type="password"],
    input[type="datetime"],
    input[type="datetime-local"],
    input[type="date"],
    input[type="month"],
    input[type="time"],
    input[type="week"],
    input[type="number"],
    input[type="email"],
    textarea.inp-pd,
    input[type="url"] {
        font-size: 16px !important;
    }

    .gemslider button.slick-prev.slick-arrow {
        left: -25px;
        font-size: 16px;
        top: 3px;
    }

    .gemslider button.slick-next.slick-arrow {
        right: -25px;
        font-size: 16px;
        top: 3px;
    }
}

@media only screen and (device-width : 375px) and (device-height : 812px) and (-webkit-device-pixel-ratio : 3) {

    select,
    textarea,
    input[type="text"],
    input[type="password"],
    input[type="datetime"],
    input[type="datetime-local"],
    input[type="date"],
    input[type="month"],
    input[type="time"],
    input[type="week"],
    input[type="number"],
    input[type="email"],
    textarea.inp-pd,
    input[type="url"] {
        font-size: 16px !important;
    }
}

@media only screen and (device-width : 375px) and (device-height : 667px) and (-webkit-device-pixel-ratio : 2) {

    select,
    textarea,
    input[type="text"],
    input[type="password"],
    input[type="datetime"],
    input[type="datetime-local"],
    input[type="date"],
    input[type="month"],
    input[type="time"],
    input[type="week"],
    input[type="number"],
    input[type="email"],
    textarea.inp-pd,
    input[type="url"] {
        font-size: 16px !important;
    }
}

@media only screen and (device-width : 414px) and (device-height : 736px) and (-webkit-device-pixel-ratio : 3) {

    select,
    textarea,
    input[type="text"],
    input[type="password"],
    input[type="datetime"],
    input[type="datetime-local"],
    input[type="date"],
    input[type="month"],
    input[type="time"],
    input[type="week"],
    input[type="number"],
    input[type="email"],
    textarea.inp-pd,
    input[type="url"] {
        font-size: 16px !important;
    }
}

/*iphone css zoom*/

/*scroll top*/
.gs_scroll_top,
.gs_scroll_top:focus,
.gs_scroll_top:hover,
.gs_scroll_top:active {
    border-radius: 50% !important;
    width: 50px;
    height: 50px;
    position: fixed;
    bottom: 40px;
    right: 30px;
    cursor: pointer;
    z-index: 99;
}

.rcs_scroll_smooth {
    scroll-behavior: smooth !important;
}

/*scroll top*/
/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
    scrollbar-width: auto;
    scrollbar-color: #4a4a4a #f2f2f2;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 10px;
}

*::-webkit-scrollbar-track {
    background: #f2f2f2;
}

*::-webkit-scrollbar-thumb {
    background-color: #4a4a4a;
    border-radius: 10px;
    border: 2px solid #f2f2f2;
}

::selection {
    color: #fff;
    background: #000;
}

/* ===== Scrollbar CSS ===== */


.rcs_custome_container {
    width: 100%;
    max-width: 1440px !important;
    position: relative;
    display: block;
    margin: 0 auto;
}

/* .modal-header button span {
        font-size: 55px;
        font-weight: 100;
    } */
.modal-header button span {
    font-size: 45px;
    font-weight: 400;
}

.rcs_fill_button,
.rcs_fill_button:hover,
.rcs_fill_button:active,
.rcs_fill_button:focus {
    border-radius: 3px !important;
    text-transform: uppercase !important;
    padding: 10px 40px !important;
    /* font-weight: 500 !important; */
    font-family: 'Adobe Garamond Pro Regular' !important;
    font-size: 13px !important;
    outline: none !important;
    box-shadow: none !important;
    letter-spacing: 0.125rem !important;
}



/* .rcs_fill_button::before {
        position: absolute;
        top: 4px;
        left: 3px;
        right: 0;
        width: 90%;
        height: calc(100% - 8px);
        z-index: 1;
        content: "";
        margin: 0 auto 0;
    } */
.rcs_fill_light_button {
    outline: none !important;
    box-shadow: none !important;
    border-radius: 0 !important;
}

.rcs_outline_button {
    outline: none !important;
    box-shadow: none !important;
    border-radius: 0 !important;
}

.rcs_blank_button {
    outline: none !important;
    box-shadow: none !important;
    border-radius: 0 !important;
}

.rcs_loader {
    width: 20px !important;
    height: 20px !important;
    position: relative;
    top: 3px;
    right: -15px;
}

.rcs_top_heading h2,
.rcs_top_heading h1 {
    font-family: 'MTD AlFresco' !important;
    font-style: normal;
    text-shadow: none;
    line-height: 1.00;
    font-size: 35px;
    margin: 10px 0;
}

.rcs_filter_wrapper {
    width: 100%;
    position: relative;
}

.rcs_filter_loader {
    position: absolute;
    left: 0px;
    right: 0px;
    margin: 0 auto;
    top: 410px;
    z-index: 999999;
    border-radius: 50%;
    width: 55px !important;
    height: 55px !important;
    padding: 10px;
}

/* .modal-backdrop {
        z-index: 9999999 !important;
    }
    .modal {
        z-index: 99999999 !important;
    } */
.rcs_filter_loader1 {
    position: absolute;
    left: 0px;
    right: 0px;
    margin: 0 auto;
    top: 450px;
    z-index: 999999;
    border-radius: 50%;
    width: 55px !important;
    height: 55px !important;
    padding: 10px;
}

/* loader for list filter */
.rcs_filter_prod_wrapper {
    width: 100%;
    position: relative;
}

.rcs_filter_prod_loader {
    position: absolute;
    left: 0px;
    right: 0px;
    margin: 0 auto;
    top: 174px;
    z-index: 999999;
    border-radius: 50%;
    width: 42px !important;
    height: 42px !important;
    padding: 10px;
}

@media (max-width:575px) and (min-width:320px) {
    .rcs_fill_button::before {
        left: 0;
        width: 86%;
    }

    .rcs_filter_loader,
    .rcs_filter_loader1 {
        position: absolute;
        left: 68px;
        top: 287px;
        width: 55px !important;
        height: 55px !important;
    }

    .rcs_filter_prod_loader {
        top: 40px;
    }
}

@media (max-width:991px) and (min-width:768px) {
    .rcs_filter_loader {
        left: 40%;
        top: 400px;
    }
}

/*Custome Modal Css*/
.modal-header {
    padding: 10px 20px 5px;
}

.modal-title.h4 {
    font-size: 1.50rem;
    line-height: 1.5;
    letter-spacing: 0.125rem;
    font-weight: 500;
    font-weight: 400;
    text-transform: uppercase;
}

.rcs_cancel_button,
.rcs_cancel_button:hover,
.rcs_cancel_button:active,
.rcs_cancel_button:focus {
    border-radius: 0 !important;
    text-transform: capitalize !important;
    font-weight: 500 !important;
    padding: 10px 40px !important;
    font-size: 16px !important;
}

/* .rcs_cancel_button::before {
        position: absolute;
        top: 4px;
        left: 4px;
        right: 0;
        width: calc(100% - 8px);
        height: calc(100% - 8px);
        z-index: 1;
        content: "";
    } */
.rcs_save_button,
.rcs_save_button:hover,
.rcs_save_button:active,
.rcs_save_button:focus {
    /* border-radius: 0 !important;
        text-transform: capitalize !important;
        padding: 10px 40px !important;
        font-family: 'Inter', sans-serif !important;
        font-weight: 500 !important;
        font-size: 16px !important; */
    border-radius: 0 !important;
    text-transform: capitalize !important;
    padding: 10px 20px !important;
    font-weight: 500 !important;
    font-size: 16px !important;
}

.rcs_save_button1,
.rcs_save_button1:hover,
.rcs_save_button1:active,
.rcs_save_button1:focus {
    margin: 0 auto !important;
    display: block !important;
}

/* .rcs_save_button::before {
        position: absolute;
        top: 4px;
        left: 4px;
        right: 0;
        width: calc(100% - 8px);
        height: calc(100% - 8px);
        z-index: 1;
        content: "";
    } */
.modal-body {
    padding: 20px 20px 0;
}

.modal-header .close {
    padding: 0 0.5rem 1rem;
    margin: -1.2rem -1rem -1rem auto;
}

.gs_news_form input::-webkit-input-placeholder {
    /* Edge */
    color: #fff;
}

.gs_news_form input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #fff;
}

.gs_news_form input::placeholder {
    color: #fff;
}

/*Custome Modal Css*/
.gs_loader1 .MuiCircularProgress-root {
    position: absolute;
    top: 45%;
    left: 50%;
    width: 50px !important;
    height: 50px !important;
}

.gs_loader1 {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

/*Diamonds By Rothschild btn css*/
/* button.rcs_fill_button-radius {
        text-transform: uppercase !important;
        padding: 8px 30px !important;
        background: #000;
        border-color: #000;
        border-radius: 3px !important;
    } */
.rcs_btn_rd-0 {
    border-radius: 0 !important;
}

.rcs_btn_shadow {
    box-shadow: 0px 3px 7px 0px #1212124f !important;
}

.rcs_customer_google_rating_star h4 {
    margin-right: 10px;
    margin-left: 5px;
    font-weight: bold;
    font-family: 'Montserrat' !important;
    margin-bottom: 0;
}

.rcs_customer_google_rating_star {
    display: flex;
    align-items: center;
}

.rcs_customer_review_btn button,
.rcs_customer_review_btn button:active,
.rcs_customer_review_btn button:hover,
.rcs_customer_review_btn button:focus {
    background: #1B1A4A !important;
    border: #1B1A4A !important;
    border-radius: 0 !important;
}

.rcs_customer_review_btn button:hover {
    background: #1B1A4A !important;
    border: #1B1A4A !important;
    border-radius: 0 !important;
    padding: 10px 20px !important;
    letter-spacing: inherit !important;
}

/*Diamonds By Rothschild btn css*/

/*Responsive css*/
.gs_myaccount.gs_order_success p {
    margin: 0;
}

.gs_myaccount.gs_order_success {
    text-align: center;
    margin: 40px 0;
}

@media (max-width:1199px) {

    .rcs_filter_prod_loader {
        display: none;
    }

}

@media (min-width: 768px) and (max-width: 992px) {
    .rcs_cancel_button {
        padding: 10px 23px !important;
    }

    .modal-footer {
        display: block ruby;
    }
}

@media (min-width: 320px) and (max-width: 767px) {
    .mobMb0{
        margin-bottom: 0px !important;
    }
    .mt-5.uniqDiamond {
        margin-top: 20px !important;
      }

    .rcs_top_heading h2,
    .rcs_top_heading h1 {
        font-size: 30px;
        margin: 10px 0 20px;
    }

    .modal-title.h4 {
        font-size: 16px;
    }

    .rcs_cancel_button,
    .rcs_cancel_button:hover,
    .rcs_cancel_button:active,
    .rcs_cancel_button:focus {
        padding: 10px 25px !important;
    }

    .modal-body .modal-footer {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .modal-footer {
        padding-right: 15px !important;
        display: inline-block !important;
    }

    .rcs_custom_home_container.container {
        max-width: 100% !important;
        width: 100% !important;
    }

    .rcs_make_an_appointment_modal_footer.modal-footer {
        text-align: center;
        width: 100%;
    }
}

/*Responsive css*/

.swal-button,
.swal-button:hover,
.swal-button:active,
.swal-button:focus {
    border-radius: 0 !important;
    text-transform: capitalize !important;
    padding: 10px 40px !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    box-shadow: none;
}

.swal-button::before {
    position: absolute;
    top: 4px;
    left: 4px;
    right: 0;
    width: calc(100% - 8px);
    height: calc(100% - 8px);
    z-index: 1;
    content: "";
}

.swal-footer {
    text-align: center !important;
}

.swal-title {
    font-size: 18px !important;
}

.MuiDrawer-root.MuiDrawer-modal {
    z-index: 9999999 !important;
}

.MuiDrawer-root.MuiDrawer-modal.css-y28f86 {
    z-index: 1200 !important;
}

a.whatsapp_float {
    position: fixed;
    bottom: 30px;
    right: 20px;
}

a.whatsapp_float img {
    width: 42px;
    z-index: 99;
}

.modal-open123 .fade.modal-backdrop.show {
    opacity: 0;
}

.rcs_about_our_history_video_text_content h4 {
    margin-bottom: 10px;
}

.rcs_about_our_history_video_text_content h4 a {
    color: #000000;
    cursor: pointer;
}

.rcs_bg_inner {
    background-image: url('../images/bg-inner.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 5rem 0;
}

/* .rcs_bg_inner.rcs_jewelry_bg_img {
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
} */

.rcs_jewelry_new_box {
    height: 300px;
    margin-bottom: 1.5rem;
    display: flex;
    align-items: flex-end;
    padding: 15px 20px;
    position: relative;
    overflow: hidden;
}

.rcs_jewelry_new_box .img1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 0.5s;
}

.rcs_jewelry_new_section {
    padding-bottom: 70px;
}
.rcs_jewelry_new_title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    z-index: 99;
}

/* .rcs_jewelry_new_title a {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    z-index: 99;
} */
.rcs_jewelry_new_title a h3 {
    margin: 0;
}
a.rcs_jewelry_new_box_a {
    color: white;
}
.rcs_jewelry_new_box:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg,#fa0a0a00 55.56%,#0000004a);
    z-index: 9;
}
.rcs_jewelry_new_box .rcs_jewelry_new_box_img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 0.5s;
    transform: scale(1);
}
.rcs_jewelry_new_box:hover .rcs_jewelry_new_box_img {
    transform: scale(1.1);
    transition: all 0.5s;
}

.rcs_bg_inner .rcs_breadcrumb ol {
    justify-content: center;
}

.rcs_bg_inner_engagement {
    background-image: url('../images/bg-engagement.png');
    background-size: cover;
    background-position: left;
    background-repeat: no-repeat;
    padding: 7rem 0;
}

.rcs_bg_inner_engagement .rcs_breadcrumb ol {
    justify-content: center;
}
.rcs_modal_124 .modal-dialog .modal-content {
    border: none;
}

@media (min-width: 1200px) and (max-width: 1440px) {
    .rcs_filter_loader {
        top: 280px;
    }
}
@media (min-width: 768px) and (max-width: 1024px) {
    .rcs_bg_inner {
        padding: 3rem 0;
    }

    .rcs_bg_inner_engagement {
        padding: 3rem 0;
    }
}

@media (min-width: 320px) and (max-width: 767px) {
    .rcs_bg_inner {
        background-image: url('../images/bg-inner-mob.png');
        padding: 3rem 0;
    }

    .rcs_bg_inner_engagement {
        background-image: url('../images/bg-engagement-mob.png');
        padding: 3rem 0;
    }
}

@media (min-width: 576px) {
    .rcs_login_modal .modal-dialog {
        max-width: 400px;
        margin: 1.75rem auto;
    }
}
.rcs_custom_select_country select {
    padding:13.5px 8px;
    margin-bottom: 25px;
    width: 100%;
    border-color: #c4c4c4;
    border-radius: 5px;
    font-size: 14px;
    box-shadow: none;
}

      /*watches*/
      .watchFilters {
        border-bottom: 1px solid #ddd;
      }
      .noShadow {
        box-shadow: none !important;
      }
      .rcs_accordion.watchFilters {
        margin-bottom: 20px;
      }
      .imgWrapperWatch {
        display: block;
        padding: 20px 50px 10px;
      }
      .watchList h3, .watchList p {
        text-align: center !important;
      }
      .parentListWatch > div {
        height: 100%;
        margin-bottom: 0;
      }
      .parentListWatch {
        margin-bottom: 30px;
      }
      .sidebarWrapper {
        position: -webkit-sticky;
        position: sticky;
        top: 98px;
      }
      .rcs_filter_list h5 .clear-all-filters {
        background: transparent;
        border: none;
        display: inline-block;
        float: none;
        font-size: .7rem;
        font-weight: 400;
        letter-spacing: .03rem;
        text-decoration: underline;
        white-space: nowrap;
        width: auto;
      }
      .noBtn {
        background: transparent;
        border: none;
        outline: none;
      }
      .fw600 {
        font-weight: 600;
      }
      .fs16 {
        font-size: 16px;
      }
      .drawerX {
        padding-left: 15px;
        padding-right: 15px;
      }
      @media only screen and (max-width: 1024px) and (min-width: 767px){
        .rcs_filter_list h5 {
            font-size: 15px;
          }
          .rcs_price_filter label.MuiFormControlLabel-root > span.MuiFormControlLabel-label {
            font-size: 12px;
          }
      }
      @media only screen and (max-width: 767px) and (min-width: 300px){
      .rcs_view.mobPb15.mb-5 {
        margin-bottom: 10px !important;
      }
      .mobPb15 {
        padding-bottom: 15px !important;
        padding-top: 15px !important;
      }
    }
          /*watches*/
          .colorBlack {
            color: #000 !important;
          }
          .uniqDiamond a:hover {
            color: #7c7c7c !important;
          }
          .uniqDiamond img {
            max-width: 330px;
            width: 100%;
          }
          .mb60 {
            margin-bottom: 60px;
          }
       
          .uniqDiamond h3 {
            font-size: 20px;
            max-height: 50px;
            overflow: hidden;
            position: relative;
          }
          .uniqDiamond h3 a{
            -webkit-transition: all 0.3s ease 0s;
            transition: all 0.3s ease 0s;
          }

          .MakePayment form i {
            color: red;
          }